











































































































































import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import moment from 'moment'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import Paginate from 'vuejs-paginate'
import removeMd from 'remove-markdown'
require("moment-duration-format")

@Component({
  components: {
    ClipLoader,
    Paginate,
  },
})
export default class NoticeList extends Vue {
  LOAD_COUNT = 10
  GET_MAX_PAGE = 5
  rMd = removeMd
  isLoading = true
  tabActive: any = 'notice'
  news: any[] = []
  viewNews: any[] = []
  notices: any[] = []
  viewNotices: any[] = []
  newsAlreadyReadResult: any = ''
  noticeAlreadyReadResult: any = ''
  newsPage = 1
  newsPages = 1
  noticePage = 1
  noticePages = 1

  noticeClickCallback(pageNum: number) {
    this.isLoading = true
    this.noticePage = pageNum
    this.$router.replace({name: 'noticelist', query: {page: String(pageNum)}})
    
    this.viewNotices = []
    let promises: any[] = []

    // 二つ先のpageのデータがない場合は取得
    if (this.noticeAlreadyReadResult && (pageNum + 2) * this.LOAD_COUNT > this.notices.length) {
      promises.push(new Promise((resolve) => {
        this.noticeQuery(this.noticeAlreadyReadResult).then((data) => {
          this.setNotices(data.docs, this.noticePage)
          resolve()
        })
      }))
    }
    // 二つ前のpageのデータがない場合は取得
    else if (pageNum > this.GET_MAX_PAGE - 2 && (!this.notices[pageNum - 2 * this.LOAD_COUNT - 1] || !this.notices[pageNum - 2 * this.LOAD_COUNT - 1].id)) {
      let createTime: any
      let index = 0
      for (let i=0;!createTime;i++) {
        createTime = this.notices[i] ? this.notices[i].createTime : null
        index = i
      }
      
      index = index > this.GET_MAX_PAGE + 2 ? index - (this.LOAD_COUNT * this.GET_MAX_PAGE) : 0
      promises.push(new Promise((resolve) => {
        this.noticeQuery(createTime, 0, true).then((data) => {
          this.setNotices(data.docs.reverse(), 0, index)
          resolve()
        })
      }))
    } else {
      // 指定されたpageのデータのみをviewRoomsに設定
      for (let i = (this.noticePage - 1) * this.LOAD_COUNT; this.viewNotices.length < this.LOAD_COUNT; i++) {
        let notice = this.notices[i]
        if (!notice) {
          break
        }
        this.viewNotices.push(notice)
      }
    }

    Promise.all(promises).finally(() => {
      let count = this.getLoadFirstCount(this.noticePage)
      if (count == 0) {
        sessionStorage.setItem('noticeSearch', JSON.stringify({tabActive: this.tabActive}))
      } else {
        sessionStorage.setItem('noticeSearch', JSON.stringify({tabActive: this.tabActive, alreadyLoadCount: count, start: this.notices[count].createTime}))
      }
      this.isLoading = false
    })
  }

  newsClickCallback(pageNum: number) {
    this.isLoading = true
    this.newsPage = pageNum
    this.$router.replace({name: 'noticelist', query: {page: String(pageNum)}})
    
    this.viewNews = []
    let promises: any[] = []

    // 二つ先のpageのデータがない場合は取得
    if (this.newsAlreadyReadResult && (pageNum + 2) * this.LOAD_COUNT > this.news.length) {
      promises.push(new Promise((resolve) => {
        this.newsQuery(this.newsAlreadyReadResult).then((data) => {
          this.setNews(data.docs, this.newsPage)
          resolve()
        })
      }))
    }
    // 二つ前のpageのデータがない場合は取得
    else if (pageNum > this.GET_MAX_PAGE - 2 && (!this.news[pageNum - 2 * this.LOAD_COUNT - 1] || !this.news[pageNum - 2 * this.LOAD_COUNT - 1].id)) {
      let createTime: any
      let index = 0
      for (let i=0;!createTime;i++) {
        createTime = this.news[i] ? this.news[i].createTime : null
        index = i
      }
      
      index = index > this.GET_MAX_PAGE + 2 ? index - (this.LOAD_COUNT * this.GET_MAX_PAGE) : 0
      promises.push(new Promise((resolve) => {
        this.newsQuery(createTime, 0, true).then((data) => {
          this.setNews(data.docs.reverse(), 0, index)
          resolve()
        })
      }))
    } else {
      // 指定されたpageのデータのみをviewRoomsに設定
      for (let i = (this.newsPage - 1) * this.LOAD_COUNT; this.viewNews.length < this.LOAD_COUNT; i++) {
        let news = this.news[i]
        if (!news) {
          break
        }
        this.viewNews.push(news)
      }
    }

    Promise.all(promises).finally(() => {
      let count = this.getLoadFirstCount(this.newsPage)
      if (count == 0) {
        sessionStorage.setItem('noticeSearch', JSON.stringify({tabActive: this.tabActive}))
      } else {
        sessionStorage.setItem('noticeSearch', JSON.stringify({tabActive: this.tabActive, alreadyLoadCount: count, start: this.news[count].createTime}))
      }
      this.isLoading = false
    })
  }

  getLoadFirstCount(page: number) {
    if (page < this.GET_MAX_PAGE + 3) {
      return 0
    }
    let surplus = page % this.GET_MAX_PAGE
    let alreadyLoadPage
    if (surplus == 1) {
      alreadyLoadPage = page - this.GET_MAX_PAGE - surplus
    } else if (surplus == 2 || surplus == 3 || surplus == 4) {
      alreadyLoadPage = page - surplus
    } else {
      alreadyLoadPage = page - this.GET_MAX_PAGE
    }
    return alreadyLoadPage * this.LOAD_COUNT
  }

  mounted() {
    this.tabActive = this.$route.query.tabActive ? this.$route.query.tabActive : this.tabActive
    let session: any = sessionStorage.getItem('noticeSearch')
    if (session) {
      session = JSON.parse(session)
      this.tabActive = session && session.tabActive ? session.tabActive : this.tabActive
    }

    if (this.$route.query.page) {
      if (this.tabActive == 'notice') {
        this.noticePage = Number(this.$route.query.page)
      } else {
        this.newsPage = Number(this.$route.query.page)
      }
    } else {
      sessionStorage.removeItem('noticeSearch')
    }
    this.search(session)
  }

  search(session?: any) {
    this.isLoading = true
    this.news = []
    this.viewNews = []
    this.viewNotices = []
    
    let page = (this.tabActive == 'notice') ? this.noticePage : this.newsPage
    let start = session && session.start ? new Date(session.start) : null
    let alreadyLoadCount = session && session.alreadyLoadCount ? session.alreadyLoadCount : null
    let alreadyLoadPage = Math.floor(alreadyLoadCount / this.LOAD_COUNT)
    if (alreadyLoadPage + this.GET_MAX_PAGE * 2 < page) {
      this.newsPage = 1
      this.noticePage = 1
      this.$router.replace({name: 'noticelist', query: {page: String(1)}})
      alreadyLoadCount = 0
      alreadyLoadPage = 0
    }

    this.newsQuery(start, this.tabActive == 'news' ? alreadyLoadCount : 0).then((data) => {
      this.setNews(data.docs, this.tabActive == 'news' ? alreadyLoadPage : 0)
    })

    this.noticeQuery(start, this.tabActive == 'notice' ? alreadyLoadCount : 0).then((data) => {
      this.setNotices(data.docs, this.tabActive == 'notice' ? alreadyLoadPage : 0)
    })
  }

  newsQuery(start?: any, noLoadCount?: number, beforeLoad = false) {
    let collection
    if (beforeLoad) {
      collection = firebase.firestore().collection('news').where('public', '==', true).orderBy('createTime').startAfter(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE)
    } else if (start) {
      if (noLoadCount) {
        for (let i=0; i < noLoadCount;i++) {
          if (noLoadCount == i + 1) {
            this.news.push(<any>{date: start})
          } else {
            this.news.push(<any>null)
          }
        }
        collection = firebase.firestore().collection('news').where('public', '==', true).orderBy('createTime', 'desc').startAt(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE * 2)
      } else {
        collection = firebase.firestore().collection('news').where('public', '==', true).orderBy('createTime', 'desc').startAfter(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE)
      }
    } else {
      collection = firebase.firestore().collection('news').where('public', '==', true).orderBy('createTime', 'desc').limit(this.LOAD_COUNT * this.GET_MAX_PAGE * 2)
    }
    return collection.get()
  }

  setNews(data: firebase.firestore.QueryDocumentSnapshot[], pages?: number, unshiftIndex: any = null) {
    let promises:any = []
    let uid = Common.getUserId()
    data.forEach((news, index) => {
      let result = news.data()
      result.id = news.id
      result.createTime = result.createTime.toDate()
      result.updateTime = result.updateTime.toDate()
      if (unshiftIndex || unshiftIndex == 0) {
        this.news[unshiftIndex++] = result
      } else {
        this.news.push(result)
      }
    })

    for (let i = (this.newsPage - 1) * this.LOAD_COUNT; this.viewNews.length < this.LOAD_COUNT; i++) {
      let news = this.news[i]
      if (!news) {
        break
      }
      this.viewNews.push(news)
    }

    if (!unshiftIndex && unshiftIndex != 0) {
      this.newsPages = pages ? (pages + Math.floor(data.length / this.LOAD_COUNT)) : data.length / this.LOAD_COUNT
      this.newsAlreadyReadResult = (data.length == this.LOAD_COUNT * this.GET_MAX_PAGE || data.length == this.LOAD_COUNT * this.GET_MAX_PAGE * 2) ? this.news[this.news.length - 1].createTime : null
    }

    return Promise.all(promises).finally(() => {this.isLoading = false})
  }

  noticeQuery(start?: any, noLoadCount?: number, beforeLoad = false) {
    let collection
    if (beforeLoad) {
      collection = firebase.firestore().collection('users').doc(Common.getUserId()).collection('news').orderBy('createTime').startAfter(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE)
    } else if (start) {
      if (noLoadCount) {
        for (let i=0; i < noLoadCount;i++) {
          if (noLoadCount == i + 1) {
            this.notices.push(<any>{date: start})
          } else {
            this.notices.push(<any>null)
          }
        }
        collection = firebase.firestore().collection('users').doc(Common.getUserId()).collection('news').orderBy('createTime', 'desc').startAt(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE * 2)
      } else {
        collection = firebase.firestore().collection('users').doc(Common.getUserId()).collection('news').orderBy('createTime', 'desc').startAfter(start).limit(this.LOAD_COUNT * this.GET_MAX_PAGE)
      }
    } else {
      collection = firebase.firestore().collection('users').doc(Common.getUserId()).collection('news').orderBy('createTime', 'desc').limit(this.LOAD_COUNT * this.GET_MAX_PAGE * 2)
    }
    return collection.get()
  }

  setNotices(data: firebase.firestore.QueryDocumentSnapshot[], pages?: number, unshiftIndex: any = null) {
    let promises:any = []
    let uid = Common.getUserId()
    data.forEach((notice, index) => {
      let result = notice.data()
      result.id = notice.id
      result.url = Common.createUserIconURL(result.uid, true)
      Common.imageExist(result.url).catch(() => result.url = '')
      result.createTime = result.createTime.toDate()
      if (unshiftIndex || unshiftIndex == 0) {
        this.notices[unshiftIndex++] = result
      } else {
        this.notices.push(result)
      }
    })

    for (let i = (this.noticePage - 1) * this.LOAD_COUNT; this.viewNotices.length < this.LOAD_COUNT; i++) {
      let notice = this.notices[i]
      if (!notice) {
        break
      }
      this.viewNotices.push(notice)
    }

    if (!unshiftIndex && unshiftIndex != 0) {
      this.noticePages = pages ? (pages + Math.floor(data.length / this.LOAD_COUNT)) : data.length / this.LOAD_COUNT
      this.noticeAlreadyReadResult = (data.length == this.LOAD_COUNT * this.GET_MAX_PAGE || data.length == this.LOAD_COUNT * this.GET_MAX_PAGE * 2) ? this.notices[this.notices.length - 1].createTime : null
    }

    return Promise.all(promises).finally(() => {this.isLoading = false})
  }

  tabClick(active: string) {
    this.tabActive = active
    let page = this.tabActive == 'notice' ? this.noticePage : this.newsPage
    let count = this.getLoadFirstCount(page)
    this.$router.replace({name: 'noticelist', query: {page: String(page)}})
    if (count == 0) {
      sessionStorage.setItem('noticeSearch', JSON.stringify({tabActive: this.tabActive}))
    } else {
      sessionStorage.setItem('noticeSearch', JSON.stringify({tabActive: this.tabActive, alreadyLoadCount: count, start: this.tabActive == 'notice' ? this.notices[count].createTime : this.news[count].createTime}))
    }
  }

  selectNotice(notice: firebase.firestore.DocumentData) {
    this.$router.push(notice.to)
  }

  selectNews(news: firebase.firestore.DocumentData) {
    this.$router.push('/news/' + news.id)
  }
}
